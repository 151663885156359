<template>
  <div>
    <v-alert
        v-html="language.orgAdmins.notice_1"
        style="background-color: rgba(250,109,7,0.86) !important;"
        type="info"
        transition="scroll-y-transition"></v-alert>
    <v-alert
        style="background-color: #80a957 !important;"
        type="info"
        transition="scroll-y-transition">{{ language.orgAdmins.notice_2 }}
    </v-alert>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <containerTitle :title="adminTitle"></containerTitle>
      <v-container>
        <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="pairArray"
            @line-field-input="checkInput(...arguments)"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Admin"
            linePrefix="organisationAdmins"
        ></lineFieldsArray>
      </v-container>
      <input type="hidden" :name="field" :value="JSON.stringify(value)"/>
    </v-card>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import containerTitle from "@/commonComponents/containerTitle.vue";
import lineButtons from "@/cfg/lineButtons.json";

export default {
  props: ["result", "field", "fieldAttributes", "value"],
  components: {lineFieldsArray, containerTitle},
  data() {
    return {
      fields: {
        id: {
          type: "hidden",
          colAttrs: {class: "d-none"},
        },
        key: {
          type: "hidden",
          colAttrs: {class: "d-none"},
        },
        email: {
          type: "text",
          name: "Email",
          fieldAttrInput: {class: " required emailCheck"},
          inputFieldFormatter: "organisationNameFormatter",
        },
        remove: lineButtons.remove,
      },
    };
  },
  computed: {
    adminTitle(){
      let title ="Admins";
      if(this.pairArray.length>0){
        title = title+" ("+this.pairArray.length+")";
      }
      return title;
    },
    pairArray() {
      let pairArray = [];
      if (Array.isArray(this.value)) {
        this.value.forEach((element) => {
          pairArray.push({
            id: element.n_id,
            key: element.n_id_key,
            email: element.c_email,
            active: element.n_active
          });
        });
      }
      return pairArray;
    },
  },
  methods: {
    checkInput(value) {
      let pairArray = [];
      value.forEach((element) => {
        pairArray.push({
          n_id: element.id,
          n_id_key: element.key,
          c_email: element.email,
          c_password: element.password,
        });
      });
      this.$emit("input", pairArray);
    },
  },
};
</script>